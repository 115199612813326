import React from "react";

export interface INextButtonProps {
  icon?: string;
  label?: string;
  className?: string;
  handleClick?: () => void;
}

export const CreateRipple = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  const button = event.currentTarget;
  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
};

const NextButton: React.FC<INextButtonProps> = ({
  label,
  icon,
  className,
  handleClick,
}) => {
  return (
    <div className="__next__btn__wrapper">
      <div
        className={`__next__btn ${className}`}
        onClick={handleClick}
        onClickCapture={CreateRipple}
      >
        {label ? label : "Next"}
        <i
          className={`${icon ? icon : "bi bi-arrow-right"} icon--pos`}
          style={{ fontSize: "18px" }}
        ></i>
      </div>
    </div>
  );
};

export default NextButton;
