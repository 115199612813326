import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios";
import LoaderOverlay from "../../Components/loader/LoaderOverlay";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import rateShopperEndPoint from "../../Apis/rateShopperEndPoint";

const ABCD = (props: any) => {
  return <div></div>;
};

const TrendGraph = () => {
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [trendData, setTrendData] = useState<any[]>([]);
  const [graphData, setGraphData] = useState<any[]>([]);

  const getTrendGraphData = async () => {
    setLoader(true);
    try {
      let graphRes = await rateShopperEndPoint.post(`/gettrendgraph`, {
        hotel_id: hotel_id,
        admin_id: admin_id,
      });
      if (graphRes.data.status === 1) {
        setTrendData(graphRes.data.trend ?? []);
        setGraphData(graphRes.data.graphdata);
        setLoader(false);
      } else {
        setTrendData([]);
        setLoader(false);
      }
    } catch {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTrendGraphData();
  }, []);

  return loader ? (
    <LoaderOverlay />
  ) : (
    <div className="trend__graph__view__wrapper">
      <div className="trend__graph__height">
        <ResponsiveLine
          data={trendData}
          margin={{ top: 30, right: 5, bottom: 80, left: 50 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          // yFormat={(value) =>
          //   `${Number(value).toLocaleString("en-US", {
          //     // minimumFractionDigits: 1,
          //   })} `
          // }
          sliceTooltip={({ slice }) => {
            const sortedPoints = [...(slice?.points ?? [])].sort(
              (a: any, b: any) => Number(b.data.y) - Number(a.data.y)
            );

            return (
              <div className="trend-graph-tooltip-wrapper">
                <div className="trend-graph-tooltip-x-axis">
                  {`Date :${slice.points[0].data.x}`}
                </div>
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    // style={{
                    //   color: point.serieColor,
                    //   padding: "3px 0",
                    // }}
                    className="trend-graph-tooltip-data-wrapper"
                  >
                    <div className="left-side-trend-data me-4 mb-2">
                      <div
                        className="me-2"
                        style={{
                          backgroundColor: point.serieColor,
                          // padding: "3px 0",
                          height: "10px",
                          width: "10px",
                        }}
                      ></div>
                      <div
                        className="serieId"
                        style={{ color: point.serieColor }}
                      >
                        {point.serieId}
                      </div>
                    </div>
                    <div
                      className="yFormatted"
                      style={{ color: point.serieColor }}
                    >
                      {point.data.yFormatted}
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
          curve="catmullRom"
          enableGridX={false}
          colors={{ scheme: "category10" }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 15,
            tickPadding: 10,
            tickRotation: -40,
            // legend: "transportation",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          // colors={"green"}
          pointSize={3}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices="x"
          // layers={[
          //   "grid",
          //   "markers",
          //   "areas",
          //   "lines",
          //   "slices",
          //   "points",
          //   "axes",
          //   "legends",
          // ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 42,
              translateY: 60,
              itemWidth: 137,
              itemHeight: 10,
              itemsSpacing: 26,
              symbolSize: 11,
              symbolShape: "circle",
              itemDirection: "left-to-right",
              // itemTextColor: "#777",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TrendGraph;
