import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import kernelApi from "../Apis/kernelApi";
import { RootState } from "../Redux/store";
import { Capitalize, Logout } from "../UtilityFunctions";
import { sha512 } from "js-sha512";

// import ManagePropertyDropdown from '../manageproperty/ManagePropertyDropdown';

const AppHeader = () => {
  const { admin_id, auth_token, full_name, hotel_name, company_id, hotel_id } =
    useSelector((state: RootState) => state.auth);

  const { directLogin } = useSelector((state: RootState) => state.auth);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [hotelName, setHotelName] = useState<string>("");

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event?.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setIsDropdownOpen]);

  useEffect(() => {
    getHotelDetails();
  }, []);

  // Api To Get Hotel Name
  const getHotelDetails = async () => {
    try {
      let comp_hash = sha512(company_id.toString());
      let hotelRes =
        await kernelApi.get(
          `/extranetv4/hotel_admin/get_all_hotels_by_company/${comp_hash}/${company_id}`,
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );

      if (hotelRes.data.status === 1) {
        setHotelName(hotelRes.data.data[0].hotel_name);
        console.log(hotelRes.data.data[0].hotel_name);
      } else {
        setHotelName("");
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  return (
    <>
      <div className="header-wrapper header-bg-color header-position">
        <div className="header__content__wrapper">
          <div className="header_hotel_name">
            <i className="bi bi-building me-2"></i>
            {hotel_name
              ? `${hotel_name} (${hotel_id})`
              : `${hotelName} (${hotel_id})`}
          </div>

          {/* <div
            ref={ref}
            className="profile-log-out-dropdown"
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
              // setIconRotate(!iconRotate);
            }}
          >
            <div className="profile-log-out-title">{Capitalize(full_name)}</div>

            <div className={`profile-log-out-icon`}>
              {isDropdownOpen ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
              
            </div>
          </div> */}

          {/* <CSSTransition
            in={isDropdownOpen}
            timeout={150}
            classNames="logout-dropdown"
            unmountOnExit
          >
            <div className="profile-details-card">
              <div className="profile-submenu">{Capitalize(full_name)}</div>

              <div
                className="profile-submenu log-out-btn"
                onClick={() => {
                  Logout();
                }}
              >
                Logout
                <span className="log-out-icon-wrapper ms-3">
                  <i className="bi bi-box-arrow-right "></i>
                </span>
              </div>
            </div>
          </CSSTransition> */}
        </div>
      </div>
    </>
  );
};

export default AppHeader;
